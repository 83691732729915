.App {
  text-align: center;
  color: white;
}

.App-header {
  font-size: calc(12px + 2vmin);
}

.header-txt {
  color: #E1C362;
  margin-block: 0px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.App-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  background-color: #282c34a9;
  padding-block: 0.75vmin;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.footer-info {
  font-size: calc(4px + 1vmin);
  width: 30%;
  margin-inline: 1vmin;
}

.search-container {
  display: flex;
  justify-content: center;
}

.searchbar {
  display: flex;
  gap: 5px;
  width: 30rem;
  margin-inline: 3%;
  margin-top: 3vmin;
}

.autocomplete-wrapper {
  flex: 1;
}

.title-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 1%;
}

.guess-title {
  margin-bottom: 1vmin;
  width: 18vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(8px + 1vmin);
  border: 1px solid #e1c36200;
}

.guesses-wrapper {
  width: 100%;
}

.title-text {
  margin: 0;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.guess-results {
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 1%;
  margin-bottom: 2%;
}

.guess-box {
  min-height: 18vmin;
  width: 18vmin;
  border: 1px solid #E1C362;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  font-size: calc(6px + 1vmin);
}

.latest-guess-results .guess-box {
  animation: FadeIn 0.5s linear;
  animation-fill-mode: both;
}

.latest-guess-results .guess-box:nth-child(1) { animation-delay: .1s }
.latest-guess-results .guess-box:nth-child(2) { animation-delay: .2s }
.latest-guess-results .guess-box:nth-child(3) { animation-delay: .3s }
.latest-guess-results .guess-box:nth-child(4) { animation-delay: .4s }
.latest-guess-results .guess-box:nth-child(5) { animation-delay: .5s }

.guess-text {
  margin-block: 1vmin;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black
}

.guess-right {
  background-color: darkolivegreen;
}

.guess-right-cb {
  background-color: rgb(56, 213, 213);
}

.guess-partial {
  background-color: darkgoldenrod;
}

.guess-partial-cb {
  background-color: rgb(177, 94, 177);
}

.guess-wrong {
  background-color: darkred;
}

.guess-wrong-cb {
  background-color: rgb(123, 119, 119);
}

.autocomplete-wrapper .MuiAutocomplete-inputRoot,
.autocomplete-wrapper .MuiInputLabel-root,
.autocomplete-wrapper .MuiInputLabel-root.Mui-focused {
  color: white;
  font-size: calc(8px + 1vmin);
}

.autocomplete-wrapper .MuiOutlinedInput-notchedOutline, .autocomplete-wrapper .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.autocomplete-wrapper .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #E1C362;
}

.autocomplete-wrapper .MuiInputBase-root {
  background-color: #282c34;
}

.autocomplete-wrapper .MuiSvgIcon-root {
  fill: white;
}

.autocomplete-wrapper .MuiOutlinedInput-root:hover .MuiSvgIcon-root,
.autocomplete-wrapper .MuiOutlinedInput-root.Mui-focused .MuiSvgIcon-root {
  fill: #E1C362;
}

.autocomplete-wrapper .autocomplete-search .MuiAutocomplete-inputRoot .MuiPopper-root .MuiAutocomplete-popper .MuiAutocomplete-popperDisablePortal {
  background-color: #282c34;
  color: white;
}

.autocomplete-wrapper .MuiAutocomplete-listbox {
  background-color: #282c34;
  color: white;
  border:1px solid #E1C362;
}

.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 0px;
}

.guess-btn {
  color: white;
  border-radius: 4px;
  background-color: #282c34;
  border: 1px solid #E1C362;
}

.guess-btn:hover {
  background-color: #2c2f34;
  color: #E1C362;
  cursor: pointer;
}

.guess-btn:disabled,
.guess-btn:disabled:hover{
  background-color: grey;
  color: black;
  border-color: #1c1e22;
  cursor: not-allowed;
}

.share-results-btn {
  margin-top: 1vmin;
  padding: 0.5vmin;
  color: white;
  border-radius: 4px;
  background-color: #282c34;
  border: 1px solid #E1C362;
  font-size: calc(8px + 2vmin);
}

.share-results-btn:hover {
  background-color: #2c2f34;
  color: #E1C362;
  cursor: pointer;
}

.modal-overlay {
  background: rgba(49,49,49,0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999;
  
}


.modal-content {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  line-height: 1.4;
  background: #282c34;
  padding: 1rem 2rem;
  width: 60%;
  height: auto;
  max-height: 75%;
  z-index: 1000;
  border: 2px solid #E1C362;
  border-radius: 0.8rem;
  overflow: scroll;

  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.modal-content::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

.modal-title {
  margin-top: 0;
  text-decoration: underline;
  font-size: calc(16px + 2vmin);
  color: #E1C362;
}

.modal-text {
  font-size: calc(12px + 1vmin);
}

.external-link:link, .external-link:visited {
  color: #E1C362;
  text-decoration: none;
}

.external-link:hover {
  text-decoration: underline;
}

.game-timer {
  color: #E1C362;
  font-size: calc(10px + 1vmin);
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  margin-top: 3.5vmin;
}

.modal-timer {
  font-size: calc(10px + 1vmin);
}

.background-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -10;
}

.settings-btn {
  background-image: url("../public/images/settingsInactive.png");
  background-size: contain;
}

.settings-btn:hover {
  background-image: url("../public/images/settingsActive.png");
}

.menu-btn {
  width: 8vmin;
  height: 8vmin;
  color: white;
  border-radius: 4vmin;
  background-color: #282c34;
  border: 2px solid #E1C362;
  font-size: 3vmin;
}

.menu-btn:hover {
  cursor: pointer;
  background-color: #2c2f34;
  color: #E1C362;
}

.left-menus {
  position: fixed;
  top: 2.5vmin;
  left: 4vmin;
  display: flex;
  gap: 1vmin;
}

.right-menus {
  position: fixed;
  top: 2.5vmin;
  right: 4vmin;
  display: flex;
  gap: 1vmin;
}

.incorrect-text {
  color: darkred;
}

.incorrect-text-cb {
  color: rgb(123, 119, 119);
}

.correct-text {
  color: darkolivegreen;
}

.correct-text-cb {
  color: rgb(56, 213, 213);
}

.partial-text {
  color: darkgoldenrod;
}

.partial-text-cb {
  color: rgb(177, 94, 177);
}

.example-img {
  width: 15vmin;
  height: 15vmin;
}

.rules-text {
  display: flex;
  flex-direction: column;
}

.rules-text {
  text-align: left;
}

.example-container {
  display: flex;
  align-items: center;
}

.example-img {
  margin-right: 10px; 
}

.contact-link {
  color: #E1C362;
  background: none;
  border: none;
  font-size: calc(4px + 1vmin)
}

.contact-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.kofi-btn {
  width: 8vmin;
  height: 8vmin;
  z-index: 1000;
}

.kofi-img {
  height: 100%;
  width: 100%;
}

.kofi-btn:hover {
  transform: scale(1.125);
}

.give-up-btn {
  margin-top: 1.5vmin;
  color: white;
  border-radius: 4px;
  background-color: #282c34;
  border: 1px solid #E1C362;
  font-size: calc(8px + 1vmin);
  padding: 1vmin;
  padding-inline: 2vmin;
}

.give-up-btn:hover {
  background-color: #2c2f34;
  color: #E1C362;
  cursor: pointer;
}

.tooltip {
  font-size: calc(4px + 1vmin);
}

.changelog-list {
  padding-left: 2vmin;
}

.changelog-item {
  text-align: left;
}

.changelog-heading {
  color: #E1C362;
  list-style-type: none;
}

.changelog-date {
  font-size: calc(12px + 2vmin);
}

.rules-btn {
  background-image: url("../public/images/rulesInactive.png");
  background-size: contain;
}

.rules-btn:hover {
  background-image: url("../public/images/rulesActive.png");
}

.changelog-btn {
  background-image: url("../public/images/changelogInactive.png");
  background-size: contain;
}

.changelog-btn:hover {
  background-image: url("../public/images/changelogActive.png");
}

.disclaimer-btn {
  background-image: url("../public/images/Skybreakers_glyph.png");
  background-size: contain;
}

.exit-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 5vmin;
}

@media only screen and (min-width: 1080px) {
  .guess-results {
    margin-bottom: 1%;
  }

  .menu-btn, .kofi-btn {
    width: 6vmin;
    height: 6vmin;
    border-radius: 3vmin;
  }

  .game-timer {
    margin-top: 1vmin;
  }

  .modal-content{
    width: 30%;
  }

  .exit-btn{
    width: 4vmin;
  }
}

@media only screen and (min-width: 1600px) {
  .modal-content{
    width: 40%;
  }

  .exit-btn{
    width: 4vmin;
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: scale(.1)
  }
  85% {
    opacity: 1;
    transform: scale(1.03)
  }
  100% {
    transform: scale(1)
  }
}

@keyframes Delay {
  0% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}